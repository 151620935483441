<template>
    <div class="dataListPage" v-loading="loading">
        <!-- 头部大图 -->
        <div class="dlBigImg"><img src="http://iv.okvu.cn/vugw/img/wzyt.jpg" alt="" /></div>
        <!-- 文章内容 -->
        <div class="dlpBox">
            <!-- 标题 -->
            <div class="dlpTitle">
                <!-- 上一篇下一篇 -->
                <div class="dlpBackNext" v-if="obj.beforeafter">
                    <div class="dlpBack" @click="goo(obj.beforeafter.before_id)">
                        <div><img src="https://iv.okvu.cn/vugw/img/vu818.png" alt="" /></div>
                        <span>上一篇：{{ obj.beforeafter.before_title }}</span>
                    </div>
                    <div class="dlpNext" @click="goo(obj.beforeafter.after_id)">
                        <span>下一篇：{{ obj.beforeafter.after_title }}</span>
                        <div><img src="https://iv.okvu.cn/vugw/img/vu818.png" alt="" /></div>
                    </div>
                </div>
                <div class="dlpBig">{{ obj.title }}</div>
                <div class="dlpSmall">
                    <p class="dsTime">{{ obj.create_time }}</p>
                </div>
                <!-- 简介 -->
                <p>
                    <img src="https://iv.okvu.cn/vugw/img/vu818dj2.png" alt="" />
                    {{ obj.intro }}
                </p>
                <img src="https://iv.okvu.cn/vugw/img/vu818dj1.png" alt="" />
            </div>
            <!-- 内容 -->
            <div class="dlpContent">
                <div class="editor-p" v-html="obj.details"></div>
            </div>
            <!-- 底部推荐 -->
            <h1 style="text-align: center; margin: 40px 0 50px">智慧金融解决方案全景</h1>
            <!-- <div class="dlpFooter">
                <ul>
                    <li v-for="(item, index) in 18" :key="index">
                        <img src="https://iv.okvu.cn/vugw/img/test6.png" alt="" />
                        <div class="ico">
                            <img src="https://iv.okvu.cn/vugw/img/vu818jy.png" alt="" />
                            <span>小程序</span>
                        </div>
                        <div class="erweima">
                            <img src="https://iv.okvu.cn/vugw/img/alewm.jpg" alt="" />
                            <p>分享机遇</p>
                        </div>
                    </li>
                </ul>
            </div> -->
            <partner-list v-if="obj.type" :type="obj.type"></partner-list>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import PartnerList from "./PartnerList.vue";
export default {
    components: { PartnerList },
    name: "partnerDetails",
    data() {
        return {
            id: null,
            obj: {},
            loading: false,
        };
    },
    created() {
        let id = this.$route.query.id;
        if (!id) return this.$router.go(-1);
        this.id = id;
        this.getData().then(res => {
            this.obj = res;
        });
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/partner/beforeafter", { params: { id: this.id } });
            return data;
        },
        goo(id) {
            this.loading = true;
            if (this.obj.type == 1) {
                this.$router.push("/partner3Details?id=" + id);
            } else if (this.obj.type == 2) {
                this.$router.push("/partner2Details?id=" + id);
            }
        },
    },
    // 路由改变但是该组件被复用时调用
    beforeRouteUpdate(to, from, next) {
        let id = to.query.id;
        if (!id) return this.$router.go(-1);
        this.id = id;
        this.getData().then(res => {
            this.obj = res;
            this.loading = false;
        });
        next();
    },
};
</script>

<style lang="less" scoped>
.dataListPage {
    // margin-top: 87px;
    // 头部大图
    .dlBigImg {
        width: 100%;
        height: 400px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    // 文章内容
    .dlpBox {
        width: 80%;
        // min-height: 1300px;
        margin: 0 auto;
        // margin-top: 100px;
        // 标题
        .dlpTitle {
            width: 100%;
            // height: 100px;
            // height: 258px;
            max-width: 1200px;
            margin: 0 auto;
            border-radius: 8px;
            box-shadow: 0px 9px 14px rgba(245, 245, 247, 0.8);
            margin-top: -100px;
            position: relative;
            z-index: 99;
            background-color: white;
            padding: 40px;
            .dlpBackNext {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: absolute;
                left: 0px;
                top: -50px;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                .dlpBack,
                .dlpNext {
                    padding: 0 25px;
                    position: relative;
                    cursor: pointer;
                    & > div {
                        position: absolute;
                        width: 30px;
                        height: 50px;
                        overflow: hidden;
                        transform: scale(0.3);
                        & > img {
                            position: absolute;
                            width: 1200px;
                        }
                    }
                }
                .dlpBack {
                    & > div {
                        left: 0px;
                        top: 0px;
                        & > img {
                            top: -643px;
                            left: -176px;
                        }
                    }
                }
                .dlpNext {
                    & > div {
                        right: 0px;
                        top: 0px;
                        & > img {
                            top: -643px;
                            left: -208px;
                        }
                    }
                }
                .dlpBack:hover {
                    color: #7781f1;
                    text-decoration: underline;
                }
                .dlpNext:hover {
                    color: #7781f1;
                    text-decoration: underline;
                }
            }
            & > img {
                position: absolute;
                right: -40px;
                bottom: -40px;
            }
            & > p {
                text-align: left;
                text-indent: 36px;
                font-size: 18px;
                line-height: 34px;
                position: relative;
                & > img {
                    position: absolute;
                    width: 60px;
                    top: -22px;
                    left: 0px;
                }
            }
            .dlpBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                font-weight: 900;
                text-align: center;
            }
            .dlpSmall {
                width: 50%;
                height: 50px;
                line-height: 50px;
                margin: 0 auto;
                text-align: center;
                font-size: 20px;
                color: gray;
                .dsTime {
                    height: 50px;
                }
            }
        }
        // 内容
        .dlpContent {
            width: 100%;
            // min-height: 800px;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                flex-direction: column;
                .dlpLi {
                    width: 100%;
                    min-height: 25px;
                    line-height: 25px;
                    margin-top: 25px;
                }
                .dlpLiB {
                    width: 100%;
                    height: 100%;
                    margin-top: 80px;
                    border-bottom: 1px solid gray;
                }
            }
        }
        // 上一篇下一篇
    }
}
// .dlpFooter {
//     max-width: 1200px;
//     margin: 0 auto;
//     & > ul {
//         width: 100%;
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-around;
//         li {
//             width: 315px;
//             height: 195px;
//             position: relative;
//             background-color: #fff;
//             border-radius: 20px;
//             overflow: hidden;
//             box-shadow: 1px 1px 10px 1px gainsboro;
//             cursor: pointer;
//             transition: 0.5s;
//             text-align: left;
//             margin-bottom: 50px;
//             &:hover {
//                 transform: scale(1.1);
//                 .erweima {
//                     opacity: 1;
//                 }
//             }
//             & > img {
//                 width: 100%;
//             }
//             .ico {
//                 position: absolute;
//                 bottom: 25px;
//                 left: 40px;
//                 img {
//                     width: 36px;
//                     height: 36px;
//                     vertical-align: middle;
//                 }
//             }
//             .erweima {
//                 opacity: 0;
//                 background: rgba(0, 0, 0, 0.5);
//                 width: 100%;
//                 height: 100%;
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 justify-content: center;
//                 transition: 0.5s;
//                 position: absolute;
//                 top: 0px;
//                 color: white;
//                 img {
//                     width: 100px;
//                     height: 100px;
//                     margin-bottom: 20px;
//                 }
//             }
//         }
//     }
// }
</style>
